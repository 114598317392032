import React from "react";
import { ClipLoader } from "react-spinners";

function LoadingSpinner() {
  return (
    <div className="loading-spinner">
      <ClipLoader color="#000" loading={true} size={25} />
    </div>
  );
}

export default LoadingSpinner;
