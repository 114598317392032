export const AuthEndpoints = {
  login: {
    url: `/users/login/`,
    method: "POST",
  },
  getMe: {
    url: "/users/get_me/",
    method: "GET",
  },
  refresh: {
    url: "/token/refresh/",
    method: "POST"
  },
  updateMe: {
    url: "/users/update_me/",
    method: "PATCH"
  },
  holidays: {
    url: "/national-holiday/",
    method: "POST"
  },
  getAllUsers: {
    url: "/users/all_users/",
    method: "GET"
  }
};
