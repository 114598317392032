import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import whatsappIcon from "../../assets/whatsapp.png";
import callIcon from "../../assets/call.png";
import arrow from "../../assets/arrow.png";
import { ProjectHourService } from "../../apis/service/ProjectHourServices";

const WeeklyReport = () => {
  const [daysByMonth, setDaysByMonth] = useState({});
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedWeek, setSelectedWeek] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [hoursBox, setHoursBox] = useState(null); // State for storing hours
  const [expandedSection, setExpandedSection] = useState(null);
  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  useEffect(() => {
    generateDays();
  }, []);

  useEffect(() => {
    getHours(from, to); // Call getHours when from or to changes
  }, [from, to]);

  // Generate days array excluding Fridays (5) and Saturdays (6)
  const generateDays = () => {
    const months = {};

    // Start from July (Month 6) to December (Month 11)
    for (let month = 6; month <= 11; month++) {
      let daysInMonth = new Date(2024, month + 1, 0).getDate();
      months[month + 1] = []; // Create an array for weeks in each month

      let currentWeek = [];
      let weekIndex = 1;

      for (let day = 1; day <= daysInMonth; day++) {
        let date = new Date(2024, month, day);
        let dayOfWeek = date.getDay();

        // Exclude Fridays (5) and Saturdays (6)
        if (dayOfWeek !== 5 && dayOfWeek !== 6) {
          currentWeek.push({ day, dayOfWeek });

          // Logic to handle the first week and subsequent weeks
          if (weekIndex === 1 && (dayOfWeek === 4 || day === daysInMonth)) {
            months[month + 1].push({ [`Week ${weekIndex}`]: [...currentWeek] });
            currentWeek = [];
            weekIndex++;
          }

          // Subsequent weeks: Add full working weeks (5 days: Sunday to Thursday)
          if (weekIndex > 1 && currentWeek.length === 5) {
            months[month + 1].push({ [`Week ${weekIndex}`]: [...currentWeek] });
            currentWeek = [];
            weekIndex++;
          }
        }
      }

      // If there are leftover days in the current week, push them to the weeks
      if (currentWeek.length > 0) {
        months[month + 1].push({ [`Week ${weekIndex}`]: [...currentWeek] });
      }
    }

    setDaysByMonth(months);
  };

  // Format date as "YYYY-MM-DD"
  const formatDate = (day, month) => {
    const formattedMonth = month < 10 ? `0${month}` : month; // Pad month with leading zero if necessary
    const formattedDay = day < 10 ? `0${day}` : day; // Pad day with leading zero if necessary
    return `2024-${formattedMonth}-${formattedDay}`;
  };

  // Fetch hours from the API
  const getHours = (fromDate, toDate) => {
    if (!fromDate || !toDate) return; // Ensure both dates are provided before making the API call
    ProjectHourService.getProjectsHours({ from: fromDate, to: toDate })
      .then((res) => {
        setHoursBox(res);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Handle week selection
  const handleWeekClick = (weekDays) => {
    const firstDay = weekDays[0].day;
    const lastDay = weekDays[weekDays.length - 1].day;
    const month = selectedMonth; // Use the selected month to format dates

    // Format dates as "YYYY-MM-DD"
    const fromDate = formatDate(firstDay, month);
    const toDate = formatDate(lastDay, month);

    setFrom(fromDate);
    setTo(toDate);
  };

  // Handle month selection
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    setSelectedWeek(""); // Reset selected week when the month changes
  };

  // Handle week selection
  const handleWeekChange = (e) => {
    setSelectedWeek(e.target.value);
    const weekKey = e.target.value;
    const selectedWeekDays = daysByMonth[selectedMonth].find(
      (weekObj) => Object.keys(weekObj)[0] === weekKey
    )[weekKey];

    handleWeekClick(selectedWeekDays);
  };

  // Helper to format week as "July W5 Sunday 28 to Wed 31"
  const formatWeek = (week, month, weekIndex) => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday"];
    const startDay = week[0];
    const endDay = week[week.length - 1];

    const monthName = new Date(2024, month - 1).toLocaleString("default", {
      month: "long",
    });

    const startDayName = daysOfWeek[startDay.dayOfWeek];
    const endDayName = daysOfWeek[endDay.dayOfWeek];

    return `${monthName} W${weekIndex}: ${startDayName} ${startDay.day} to ${endDayName} ${endDay.day}`;
  };

  const totalSubmittedHours =
    hoursBox?.working_hours_people?.users_with_hours.length || 0;
  const totalNotSubmittedHours =
    hoursBox?.working_hours_people?.users_without_hours?.length || 0;

  return (
    <div>
      {/* Month selection */}
      <div className={styles.monthContainer}>
        <label htmlFor="month-select" className={styles.monthLabel}>
          Select Month:
        </label>
        <select
          id="month-select"
          value={selectedMonth}
          onChange={handleMonthChange}
          className={styles.selectInput}
        >
          <option value="">Select a Month</option>
          {Object.keys(daysByMonth).map((month) => (
            <option key={month} value={month}>
              {new Date(2024, month - 1).toLocaleString("default", {
                month: "long",
              })}
            </option>
          ))}
        </select>
      </div>

      {/* Week selection */}
      {selectedMonth && (
        <div className={styles.monthContainer}>
          <label htmlFor="week-select" className={styles.monthLabel}>
            Select Week:
          </label>
          <select
            id="week-select"
            value={selectedWeek}
            onChange={handleWeekChange}
            className={styles.selectInput}
          >
            <option value="">Select a Week</option>
            {daysByMonth[selectedMonth]?.map((weekObj, index) => {
              const weekKey = Object.keys(weekObj)[0];
              const formattedWeek = formatWeek(
                weekObj[weekKey],
                selectedMonth,
                index + 1
              );
              return (
                <option key={index} value={weekKey}>
                  {" "}
                  {formattedWeek}{" "}
                </option>
              );
            })}
          </select>
        </div>
      )}

      <div className={styles.tableContainer}>
        <div
          className={styles.tableCaption}
          onClick={() => toggleSection("submitted")}
        >
          Employee who submitted their hours ({totalSubmittedHours}) Out of (58){" "}
          <img src={arrow} alt="arrow" className={styles.toggleIcon} />
        </div>
        {expandedSection === "submitted" && (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Employee</th>
                <th>Total Hours</th>
                <th>Projects</th>
              </tr>
            </thead>
            <tbody>
              {hoursBox?.working_hours_people?.users_with_hours.map((item) => (
                <tr key={item.employee__email}>
                  <td>{item.employee__username}</td>
                  <td>{item.total_hours}</td>
                  <td>
                    {item.projects.map((project, index) => (
                      <div key={index}>
                        {project.project_name}: {project.project_hour} hours
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <div className={styles.tableContainer}>
        <div
          className={styles.tableCaption}
          onClick={() => toggleSection("notSubmitted")}
        >
          Employee who didn't submit their hours ({totalNotSubmittedHours}) Out
          of (58)
          <img src={arrow} alt="arrow" className={styles.toggleIcon} />
        </div>
        {expandedSection === "notSubmitted" && (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Department</th>
                <th>Contact</th>
                <th>WhatsApp</th>
              </tr>
            </thead>
            <tbody>
              {hoursBox?.working_hours_people?.users_without_hours?.map(
                (item) => (
                  <tr key={item[0]}>
                    <td>{item[0]}</td>
                    <td>{item[2]}</td>
                    <td>
                      <a href={`tel:${item[1]}`} rel="noreferrer">
                        <img src={callIcon} alt="call logo" />
                      </a>
                    </td>
                    <td>
                      <a
                        href={`https://wa.me/2${item[1]}?text=Please%20don't%20forget%20to%20log%20your%20hours.`}
                        rel="noreferrer"
                      >
                        <img src={whatsappIcon} alt="whatsapp logo" />
                      </a>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        )}
      </div>

      <div className={styles.tableContainer}>
        <div
          className={styles.tableCaption}
          onClick={() => toggleSection("onLeave")}
        >
          Employee On Vacation Or Sick Leave (
          {
            hoursBox?.working_hours_people?.users_with_day_off.filter(
              (item, index, self) =>
                self.findIndex(
                  (i) => i.employee__email === item.employee__email
                ) === index
            ).length // Count unique emails
          }
          )
          <img src={arrow} alt="arrow" className={styles.toggleIcon} />
        </div>
        {expandedSection === "onLeave" && (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Email</th>
                <th>Department</th>
                <th>Contact</th>
                <th>WhatsApp</th>
              </tr>
            </thead>
            <tbody>
              {hoursBox?.working_hours_people?.users_with_day_off
                .filter(
                  (item, index, self) =>
                    self.findIndex(
                      (i) => i.employee__email === item.employee__email
                    ) === index // Filter unique emails
                )
                .map((item) => {
                  const { employee__email, employee__title, employee__phone } =
                    item;

                  return (
                    <tr key={employee__email}>
                      <td>{employee__email}</td>
                      <td>{employee__title}</td>
                      <td>
                        <a href={`tel:${employee__phone}`} rel="noreferrer">
                          <img src={callIcon} alt="call logo" />
                        </a>
                      </td>
                      <td>
                        <a
                          href={`https://wa.me/2${employee__phone}?text=Please%20don't%20forget%20to%20log%20your%20hours.`}
                          rel="noreferrer"
                        >
                          <img src={whatsappIcon} alt="whatsapp logo" />
                        </a>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default WeeklyReport;
