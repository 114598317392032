import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { AuthServices } from "../../../apis/service/AuthServices";
import { ProjectHourService } from "../../../apis/service/ProjectHourServices";
import toast from "react-hot-toast";
import VacationsReport from "./VacationsReport";

export default function Vacations() {
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [disable, setDisable] = useState(true);

  const getUsers = () => {
    AuthServices.getAllUsers()
      .then((res) => setAllUsers(res))
      .catch((err) => console.log(err, "allUsers"));
  };
  const handleUserSelect = (e) => {
    // console.log(e.target.value);
    setSelectedUser(e.target.value);
    setDisable(false);
  };

  useEffect(() => {
    getUsers();
  }, []);
  const handleSubmit = () => {
    setDisable(true);
    const data = {
      start_date: fromDate,
      end_date: toDate,
      employee: selectedUser,
    };

    ProjectHourService.addVacation(data)
      .then((res) => {
        setDisable(false);
        setFromDate("");
        setToDate("");
        setSelectedUser();
        toast.success("you submitted the vacation for the employee");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setDisable(false);
        toast.error("the request isn't sent");
      });
    // console.log(data);
  };
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.selection}>
          <h2>Please select employee</h2>
          <select
            className={styles.sel}
            id="dateSelect"
            value={selectedUser}
            onChange={(e) => {
              handleUserSelect(e);
            }}
          >
            <option value={0}>Select your option</option>
            {allUsers.map((user) => (
              <option key={user.id} value={user.id}>
                {user.username}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.selection}>
          <h2>select a date :</h2>
          <div style={{ display: "flex", gap: "1vw" }}>
            <label>from:</label>
            <input
              type="date"
              id="fromDate"
              name="fromDate"
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", gap: "1vw" }}>
            <label>to:</label>
            <input
              type="date"
              id="toDate"
              name="toDate"
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={styles.submitButton}>
        <button disabled={disable} onClick={() => handleSubmit()}>
          submit
        </button>
      </div>
      <p className={styles.tableCaption}>Employees On Vacation</p> 
     <div className={styles.box}><VacationsReport /></div>
    </div>
  );
}
