import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { useForm } from "react-hook-form";
import { ProjectHourService } from "../../apis/service/ProjectHourServices";
import toast from "react-hot-toast";
import { ProjServices } from "../../apis/service/ProjServices";

export default function DelayedProject() {
  const [img, setImg] = useState("");
  const [availableProjects, setAvailableProjects] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm();
  const handleSubmitDelayedProjects = (values) => {
    console.log(values);
    let formData = new FormData();

    formData.append("screenshot_of_email", img[0]);
    formData.append("project", Number(values.project));
    formData.append("description", values.desc);
    formData.append("number_of_working_days", values.number);
    formData.append("start_date", values.fromDate);
    formData.append("end_date", values.toDate);
    formData.append("delay_type", "pending approval");
    ProjectHourService.addProjectDelay(formData)
      .then(() => {
        toast.success("success!");
        reset();
      })
      .catch((err) => {
        console.log(err, "err");
        toast.error("failed!");
      });
  };
  const getAllProjects = () => {
    ProjServices.UserProjects()
      .then((data) => {
        console.log(data);
        setAvailableProjects(data);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  };
  useEffect(() => {
    getAllProjects();
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.projectCompletion}>
        <h2 className={styles.title}>Delayed Projects</h2>
        <form onSubmit={handleSubmit(handleSubmitDelayedProjects)}>
          <div className={styles.contentCon}>
            <div className={styles.contentCard}>
              <div className={styles.content}>
                <p>choose the project :</p>
                <select
                  className={styles.sel}
                  id="dateSelect"
                  {...register("project")}
                >
                  <option value={0}>Select your option</option>
                  {availableProjects.map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.content}>
                <p>delayed by (days) :</p>
                <input
                  type="number"
                  id="text"
                  name="number"
                  {...register("number")}
                />
              </div>
            </div>
            <div className={styles.sentenceCon}>
              <p>Select a date for delaying : </p>
              <div className={styles.dates}>
                <label>from: </label>
                <input
                  type="date"
                  id="fromDate"
                  name="fromDate"
                  {...register("fromDate")}
                />

                <label> to: </label>
                <input
                  type="date"
                  id="toDate"
                  name="toDate"
                  {...register("toDate")}
                />
              </div>
            </div>
            <div className={styles.desc}>
              <label>Description : </label>
              <input id="desc" name="desc" {...register("desc")} />
            </div>
            <div className={styles.upload}>
              <label>Screenshot of Email:</label>

              <input
                type="file"
                id="img"
                name="img"
                accept="image/*"
                onChange={(e) => setImg(e.target.files)}
              />
            </div>
          </div>
          <div className={styles.submitButton}>
            <button type="submit">Submit Your Delayed Project</button>
          </div>
        </form>
      </div>
    </div>
  );
}
