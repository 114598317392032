import { ApiUrl } from "./Domains";
import { AuthServices } from "./service/AuthServices";

export class Network {
  constructor() {
    this.bearer = "";
    this.refresh = ""
  }
  static async fetch(url, init, addAuth) {
    this.bearer = localStorage.getItem("bearer")
    this.refresh = localStorage.getItem("refresh")
    let requestedUrl = url.includes("http") ? url : ApiUrl + `/en/api` + url;

    const response = await fetch(requestedUrl, {
      mode: "cors",
      ...init,
      headers: Network.getHeaders(init.headers, addAuth),
    });

    let promise;
    if (![204, 201, 200].includes(response.status)) {
      if (response.status === 401 && this.refresh) {
        try {
          AuthServices.RefreshToken({ refresh: this.refresh }).then((res) => {
            localStorage.setItem("bearer", res.access);
            if (res.access) {
              window.location.reload()
            }
          }).catch((err) => {
          })

        } catch (error) {
          this.bearer = ""
          localStorage.clear();
          return
        }
      }
      let promise = response.json().then((data) => {
        return Promise.reject(data);
      });
      return promise.catch((error) => {
        return Promise.reject(error);
      });

    } else if (response.status === 204) promise = Promise.resolve({});
    else promise = response.json();

    return promise;
  }

  static getHeaders(originalHeaders, auth) {
    let headers = {};
    if (auth) {
      this.bearer = localStorage.getItem("bearer");
      this.refresh = localStorage.getItem("refresh");
      headers.Authorization = `Token ${this.bearer}`;
    }
    headers = {
      ...headers,
    };
    if (originalHeaders?.["content-type"] !== "form-data") {
      headers["content-type"] = "application/json";
    }
    return headers;
  }
}
