import { useState } from "react";
import Employee from "../../../Components/employee/index";

import styles from "../main page/System.module.css";
import WeeklyReport from "../../../Components/employee/weeklyreport";

export default function Managers() {
  const [tab, setTab] = useState("daily");

  return (
    <div className={styles.container}>
      <main className={styles.contentCard}>
        <div className={styles.HoursSelector}>
          <button
            className={
              tab === "daily" ? styles.select : styles.availableProjectButton
            }
            onClick={() => {
              setTab("daily");
            }}
          >
            <p style={{ marginInline: "auto" }}>Daily Report</p>
          </button>

          <button
            className={
              tab === "weekly" ? styles.select : styles.availableProjectButton
            }
            onClick={() => setTab("weekly")}
          >
            <p style={{ marginInline: "auto" }}>Weekly Report</p>
          </button>
        </div>
        {tab === "daily" && <Employee />}
        {tab === "weekly" && <WeeklyReport />}
      </main>
    </div>
  );
}
