import React, { useState } from "react";
import ActualHours from "./ActualHours";
import BillableHours from "./BillableHours";
import AddMoreHours from "./AddMoreHours";
import styles from "./System.module.css";

// Main component
const Slotsystem = () => {
  const [tab, setTab] = useState("actual");

  return (
    <div className={styles.container}>
      <main className={styles.contentCard}>
        <div className={styles.HoursSelector}>
          <button
            className={
              tab === "actual" ? styles.select : styles.availableProjectButton
            }
            onClick={() => {
              setTab("actual");
            }}
          >
            <p style={{ marginInline: "auto" }}>Actual Hours</p>
          </button>

          <button
            className={
              tab === "billable" ? styles.select : styles.availableProjectButton
            }
            onClick={() => setTab("billable")}
          >
            <p style={{ marginInline: "auto" }}>Billable Hours</p>
          </button>

          <button
            className={
              tab === "moreHours"
                ? styles.select
                : styles.availableProjectButton
            }
            onClick={() => setTab("moreHours")}
          >
            <p style={{ marginInline: "auto" }}>Add More Hours</p>
          </button>
        </div>
        {tab === "actual" && <ActualHours />}
        {tab === "billable" && <BillableHours />}
        {tab === "moreHours" && <AddMoreHours />}
      </main>
    </div>
  );
};
export default Slotsystem;
