import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProjServices } from "../../../apis/service/ProjServices";
import "./ProjectRetainerStyle.css"; 

const ProjectRetainer = () => {
  const [projects, setProjects] = useState([]);

  const getAllProjects = () => {
    ProjServices.getRetainerProjects()
      .then((res) => {setProjects(res); })
      .catch((err) => {console.log(err);});
  };

  useEffect(() => {getAllProjects();}, []);

  return (
    <div className="retainerProjects">
      <center><h3>Project Retainer</h3><br/></center>
      {projects.length > 0 ? (
        <ul>
          {projects.map((project) => (
            <li key={project.id} className="project-item">
              <h2>{project.name}</h2>
              <Link to={`/ProjectRetainer/${project.id}`}>
                <button>Project Details</button>
              </Link>
            </li>
          ))}
        </ul>
      ) : (<p>No projects available</p>)}
    </div>
  );
};
export default ProjectRetainer;