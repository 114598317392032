import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import styles from "./System.module.css";
import toast from "react-hot-toast";
import { ProjServices } from "../../../apis/service/ProjServices";
import { ProjectHourService } from "../../../apis/service/ProjectHourServices";
import LoadingSpinner from "../../../Components/LoadingSpinner";

function ActualHours() {
  const [selectedDay, setSelectedDay] = useState(""); //keep track of the selected day
  const [hoursNotSubmitted, setHoursNotSubmitted] = useState(); //keep track of the selected day
  const [tableData, setTableData] = useState([]);
  const [totalSlotDuration, setTotalSlotDuration] = useState(0);
  const [displayedSelectedProjects, setdisplayedSelectedProjects] = useState(
    []
  );
  const [selectedSlot, setSelectedSlot] = useState(""); //store selected slot duration
  const [selectedTopic, setSelectedTopic] = useState("");
  const [isTopicDropDownOpen, setIsTopicDropDownOpen] = useState(false);
  const [isTakingRestDayOff, setIsTakingRestDayOff] = useState(false);

  const [reasonForRest, setReasonForRest] = useState("");

  const [availableProjects, setAvailableProjects] = useState([]); // State for projects from the backend
  const [daysArray, setDaysArray] = useState([]);
  const [workingDates, setWorkingDates] = useState([]); //hold the next five working dates
  const [todayWorkedOnProjects, setTodayProjects] = useState([]);
  const [date, setDate] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]); //store selected projects
  const [disable, setDisable] = useState(true);
  const [isMultiple, setisMultiple] = useState(false);
  const [todayprojects, settodayprojects] = useState();
  const [editingRow, setEditingRow] = useState(null); // Track editing state for each row
  const [yesterdayWorkedOnProjects, setYesterdayProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  // get available projects frombackend and set them in state variable
  useEffect(() => {
    ProjServices.UserProjects()
      .then((data) => {
        setAvailableProjects(data);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
    getWorkingDays();
  }, []);

  useEffect(() => {
    let today = new Date();
    let day = String(today.getDate()).padStart(2, "0"); // Ensures two digits
    let month = String(today.getMonth() + 1).padStart(2, "0"); // Ensures two digits and months are zero-based
    let year = today.getFullYear();
    let formattedDate = `${year}-${month}-${day}`;
    setDate(formattedDate);
    getTodayProjects();
  }, []);

  useEffect(() => {
    collectData();
  }, [todayprojects]);

  const getWorkingDays = () =>
    ProjServices.getDays()
      .then((data) => {
        setDaysArray(data);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });

  useEffect(() => {
    const weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const nextWorkingDates = [];

    daysArray.forEach((item, i) => {
      let day = new Date(item.date);
      let hours = item.hours_not_submitted;

      if (day.getDay() !== 5 && day.getDay() !== 6) {
        // Format the date as "YYYY-MM-DD"
        const formattedDate = `${day.getFullYear()}-${String(
          day.getMonth() + 1
        ).padStart(2, "0")}-${String(day.getDate()).padStart(2, "0")}`;
        const dayName = weekDays[day.getDay()]; // Get the day name
        if (nextWorkingDates.length < 5) {
          nextWorkingDates.push({
            date: formattedDate,
            dayName,
            hoursNotSubmitted: hours,
          });
        }
      }
    });
    setWorkingDates(nextWorkingDates);
    setSelectedDay(nextWorkingDates[0]?.date);
    setHoursNotSubmitted(nextWorkingDates[0]?.hoursNotSubmitted);
  }, [daysArray]);

  const getTodayProjects = () => {
    ProjServices.getTodayProjects().then((data) => {
      settodayprojects(data);
    });
  };

  const handleSaveEdit = () => {
    setEditingRow(null);
  };

  const handleAdd = () => {
    setDisable(false);

    if (isTakingRestDayOff) {
      if (!reasonForRest.trim()) {
        toast.error("Please provide a reason for Rest of Day Off.");
        return;
      }
      const restDayOffData = {
        projects: ["Rest of Day Off"],
        slotDuration: 0,
        reason_for_rest: reasonForRest,
      };
      setTableData([...tableData, restDayOffData]);
      setTotalSlotDuration(totalSlotDuration + restDayOffData.slotDuration);
    } else if (displayedSelectedProjects.length === 0) {
      toast.error("Please Select a Project.");
    } else if (selectedTopic === "") {
      toast.error("Please Select a Topic.");
    } else if (selectedSlot.length === 0) {
      toast.error("Please Select a Slot Duration.");
    } else {
      // Check if more than one project is selected and enforce only one hour
      if (displayedSelectedProjects.length > 1) {
        if (selectedSlot.length > 1) {
          toast.error(
            "You can only select one hour when choosing more than one project."
          );
          return;
        } else {
          setisMultiple(true);
        }
      }

      const newData = {
        projects: displayedSelectedProjects,
        slotDuration: selectedSlot.length,
        selectedTopic: selectedTopic,
        multiple: isMultiple,
      };

      // Check if an entry with the same project and topic already exists
      const existingIndex = tableData.findIndex(
        (entry) =>
          entry.projects.join(", ") === newData.projects.join(", ") &&
          entry.selectedTopic === newData.selectedTopic
      );

      if (existingIndex !== -1) {
        // If an entry with the same project and topic exists, update the slot duration
        const updatedTableData = [...tableData];
        updatedTableData[existingIndex].slotDuration += newData.slotDuration;
        setTableData(updatedTableData);
        setTotalSlotDuration(totalSlotDuration + newData.slotDuration);
      } else {
        setTableData([...tableData, newData]);
        setTotalSlotDuration(totalSlotDuration + newData.slotDuration);
      }
    }
    // Reset form
    setdisplayedSelectedProjects([]);
    setSelectedSlot([]);
    setSelectedTopic("");
  };

  const handleSubmit = () => {
    setDisable(true);
    setLoading(true);
    const username = localStorage.getItem("username");
    if (isTakingRestDayOff) {
      if (!reasonForRest.trim()) {
        toast.error("Please provide a reason for Rest of Day Off.");
        setDisable(false);
        setLoading(false);
        return;
      }

      const projectHourData = {
        actual_minutes: 0,
        date: selectedDay,
        rest_of_day_off: isTakingRestDayOff,
        employee: username,
        reason_for_rest: reasonForRest, // Include the reason
      };

      ProjectHourService.addProjectHour([projectHourData])
        .then((response) => {
          setDisable(false);
          setIsTakingRestDayOff(false);
          getWorkingDays();
          setTableData([]);
          setLoading(false);
          setReasonForRest(""); // Clear the reason input
          console.log("Day Off data added successfully:", response);
        })
        .catch((error) => {
          setDisable(false);
          setLoading(false);
          console.error("Error adding Day Off data:", error);
        });
    } else if (tableData.length > 0) {
      // Handle other project hour entries
      const tempArr = [];
      tableData.forEach((data) => {
        new Array(data.slotDuration).fill(null).forEach(() => {
          if (data.projects.length > 1) {
            // Handling multiple project entries
            data.projects.forEach((projectName) => {
              const projectIndex = selectedProjects.findIndex(
                (projId) =>
                  availableProjects.find((proj) => proj.id === projId)?.name ===
                  projectName
              );

              const totalBillableMinutes = Math.max(
                data.billableHoursInput * 60 || data.slotDuration * 60,
                0
              );

              // Ensure non-negative billable hours
              if (projectIndex !== -1) {
                tempArr.push({
                  topic: data.selectedTopic,
                  actual_minutes: 60 / data.projects.length,
                  date: selectedDay,
                  is_day_off: false,
                  rest_of_day_off: false,
                  project: selectedProjects[projectIndex],
                  employee: username,
                  billable_minutes:
                    totalBillableMinutes /
                    (data.projects.length * data.slotDuration),
                  description: data.descriptionInput,
                });
              }
            });
          } else if (data.projects.length === 1) {
            const projectIndex = selectedProjects.findIndex(
              (projId) =>
                availableProjects.find((proj) => proj.id === projId)?.name ===
                data.projects[0]
            );

            if (projectIndex !== -1) {
              tempArr.push({
                topic: data.selectedTopic,
                actual_minutes: 60,
                date: selectedDay,
                is_day_off: false,
                rest_of_day_off: false,
                project: selectedProjects[projectIndex], // Use the found project ID
                employee: username,
                billable_minutes:
                  (data.billableHoursInput * 60) / data.slotDuration ||
                  (data.slotDuration * 60) / data.slotDuration,
                description: data.descriptionInput,
              });
            }
          }
        });
      });
      ProjectHourService.addProjectHour(tempArr)
        .then((response) => {
          setDisable(false);
          setLoading(false);

          console.log("Project hour data added successfully:", response);
          toast.success("Hours submitted successfully!");
          // Resetting state after submission
          setSelectedDay(
            workingDates[
              (workingDates.findIndex((date) => date.date === selectedDay) +
                1) %
                workingDates.length
            ].date
          );
          setTableData([]);
          setTotalSlotDuration(0);
          setdisplayedSelectedProjects([]);
          setSelectedProjects([]);
          setSelectedSlot([]);
          setSelectedTopic("");
          setIsTakingRestDayOff(false);
          getWorkingDays();
          getTodayProjects();
          collectData();
        })
        .catch((error) => {
          setDisable(true);
          console.error("Error adding project hour data:", error);
          toast.error("Error! couldn't submit the data");
          setLoading(false);
        }).finally = () => {};
    } else {
      console.error("No data to submit.");
      toast.error("Error! their is no data to submit");
    }
  };

  const collectData = () => {
    const todayProjectsArr = todayprojects?.latest_projects?.filter(
      (item) => item.date === date
    );
    const yesterProjectsArr = todayprojects?.latest_projects?.filter(
      (item) => item.date !== date
    );
    setYesterdayProjects(yesterProjectsArr);
    setTodayProjects(todayProjectsArr);
  };

  const handleReset = () => {
    //logic to reset
    setSelectedProjects([]);
    setdisplayedSelectedProjects([]);
    setSelectedSlot("");
    setTableData([]);
    setTotalSlotDuration(0);
    setSelectedTopic("");
    setIsTakingRestDayOff(false);
  };

  const handleTopicSelection = (topic) => {
    setSelectedTopic(topic);
    setIsTopicDropDownOpen(!isTopicDropDownOpen);
  };

  const handleSlotSelection = (slot) => {
    let newSlots = [];
    for (let i = 1; i <= slot; i++) {
      newSlots.push(i);
    }
    setSelectedSlot(newSlots);
  };

  const handleDeleteRow = (index) => {
    const newData = [...tableData];
    const deletedRow = newData.splice(index, 1)[0]; // Remove the selected row
    setTableData(newData);
    setTotalSlotDuration(totalSlotDuration - deletedRow.slotDuration);
  };

  const handleEditRow = (index) => {
    setEditingRow(index);
  };

  const handleProjectSelectionButton = (projectName, projectId) => {
    if (selectedProjects.includes(projectId)) {
      // If the project ID is already in the selectedProjects, remove it
      setSelectedProjects(selectedProjects.filter((id) => id !== projectId));
      setdisplayedSelectedProjects(
        displayedSelectedProjects.filter((name) => name !== projectName)
      );
    } else {
      setSelectedProjects([...selectedProjects, projectId]);
      setdisplayedSelectedProjects([...displayedSelectedProjects, projectName]);
    }
  };

  const handleDayClick = (day, index) => {
    setSelectedDay(day);
    setHoursNotSubmitted(workingDates[index].hoursNotSubmitted);
    setTableData([]);
    setTotalSlotDuration(0);
    setdisplayedSelectedProjects([]);
    setSelectedSlot([]);
    setSelectedTopic("");
    setIsTakingRestDayOff(false);
  };

  const handleEditProjectChange = (index, value) => {
    const newData = [...tableData];
    newData[index].projects = [value];
    const x = availableProjects.find((item) => value === item.name);
    console.log(x, "xxxxxxxx");
    handleProjectSelectionButton(x.name, x.id);
    setTableData(newData);
    console.log(newData, "newDataProject");
  };

  const handleEditTopicChange = (index, value) => {
    const newData = [...tableData];
    newData[index].selectedTopic = value;
    setTableData(newData);
  };

  const handleEditSlotChange = (index, value) => {
    const newData = [...tableData];
    newData[index].slotDuration = Number(value);
    setTableData(newData);
  };

  const handleRestOffDayOff = (e) => {
    if (tableData.length > 0) {
      toast.error("Submit Your Hours First");
    } else {
      setIsTakingRestDayOff(e);
    }
  };

  return (
    <div>
      {workingDates?.length > 0 ? (
        <>
          <div className={styles.daySelector}>
            {workingDates?.map((workDate, index) => (
              <button
                key={workDate.date}
                className={selectedDay === workDate.date ? styles.active : ""}
                onClick={() => handleDayClick(workDate.date, index)}
              >
                {workDate.dayName} <br /> {workDate.date}
              </button>
            ))}
          </div>
          <div className={styles.selected}>
            <p>{selectedDay}</p>
            {hoursNotSubmitted < 8 ? (
              <p>
                You have {hoursNotSubmitted} hours to add, please help us by
                adding your hours on time
              </p>
            ) : (
              <p>
                Bit68 wishes you a good day, please help us by adding your hours
              </p>
            )}
          </div>
          <div className={styles.ToDo}>
            <h2>Projects</h2>
            <div className={styles.selectedProjects}>
              <div className={styles.selectionContainer}>
                <div className={styles.availableProjects}>
                  {availableProjects?.length > 0 ? (
                    availableProjects?.map((project, index) => (
                      <button
                        key={index}
                        className={`${styles.availableProjectButton} ${
                          displayedSelectedProjects.includes(project.name)
                            ? styles.active
                            : ""
                        }`}
                        onClick={() =>
                          handleProjectSelectionButton(project.name, project.id)
                        }
                      >
                        {project.name}
                      </button>
                    ))
                  ) : (
                    <p>No projects available.</p>
                  )}
                </div>
              </div>
            </div>
            {/* Task drop down */}
            <div className={styles.todo}>
              <h2>Tasks</h2>
              <div className={styles.availableProjects}>
                {[
                  "Meeting",
                  "Management",
                  "Developing",
                  "Testing",
                  "Reviewing",
                  "SEO work",
                  "estimates",
                  "UI/UX work",
                  "Proposals",
                ].map((topic) => (
                  <button
                    key={topic}
                    onClick={() => handleTopicSelection(topic)}
                    className={`${styles.availableProjectButton} ${
                      selectedTopic === topic ? styles.active : ""
                    }`}
                  >
                    {topic}
                  </button>
                ))}
                <button onClick={() => handleTopicSelection("")}>clear</button>
              </div>
            </div>
            {/* Part Three: Slot duration selection */}
            <div className={styles.slotDurationContainer}>
              <h2>Slot Duration</h2>
              <div className={styles.slotDuration}>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((slot, index) => (
                  <button
                    key={index}
                    onClick={() => handleSlotSelection(slot)}
                    className={selectedSlot.includes(slot) ? styles.active : ""}
                  >
                    {slot}
                  </button>
                ))}
                <label className={styles.dayoff}>
                  <input
                    type="checkbox"
                    checked={isTakingRestDayOff}
                    onChange={(e) => handleRestOffDayOff(e.target.checked)}
                  />
                  <span className={styles.customCheckbox}></span> Rest of the
                  day off
                </label>
              </div>
              {isTakingRestDayOff && (
                <div className={styles.reasonForRestContainer}>
                  <label
                    htmlFor="reasonForRest"
                    className={styles.reasonForRestLabel}
                  >
                    Reason for Rest of Day Off:
                  </label>
                  <textarea
                    id="reasonForRest"
                    value={reasonForRest}
                    onChange={(e) => setReasonForRest(e.target.value)}
                    placeholder="Provide a reason for taking Rest of Day Off"
                    className={styles.reasonForRestInput}
                  />
                </div>
              )}
            </div>

            {/* Part Four: Add and Reset buttons */}
            <div className={styles.addResetButtons}>
              <button onClick={handleAdd}>Add</button>
              <button onClick={handleReset}>Reset</button>
            </div>
            <div>
              {todayprojects?.latest_projects?.length > 0 && (
                <>
                  <p>Yesterday :</p>
                  <div className={styles.todayCon}>
                    {yesterdayWorkedOnProjects?.map((item, idx) => (
                      <div key={idx} className={styles.latestProjects}>
                        <div className={styles.pnth}>
                          <p>{item.project__name}</p>
                          <p>For {item.total_hours} Hours</p>
                        </div>
                        <div className={styles.topics}>
                          {item.topic.map((topic, idx) => (
                            <p key={idx}>{topic}</p>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  <p>Today :</p>
                  <div className={styles.todayCon}>
                    {todayWorkedOnProjects?.map((item, idx) => (
                      <div key={idx} className={styles.latestProjects}>
                        <div className={styles.pnth}>
                          <p>{item.project__name}</p>
                          <p>For {item.total_hours} Hours</p>
                        </div>
                        <div className={styles.topics}>
                          {item.topic.map((topic, idx) => (
                            <p key={idx}>{topic}</p>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Table */}
          <div className={styles.table}>
            {tableData.length === 0 ? (
              // empty table with one empty cell
              <table>
                <thead>
                  <tr>
                    <th>Project Name</th>
                    <th>Working On</th>
                    <th>Slot Duration</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className={styles.scrollableTable}>
                  <tr>
                    <td>&nbsp;</td> <td>&nbsp;</td> <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              // Render the table with data
              <table>
                <thead>
                  <tr>
                    <th>Project Name</th>
                    <th>Working on</th>
                    <th>Slot Duration</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className={styles.scrollableTable}>
                  {tableData.map((data, index) => (
                    <tr key={index}>
                      <td>
                        {editingRow === index ? (
                          <select
                            value={data.projects[0]}
                            onChange={(e) =>
                              handleEditProjectChange(index, e.target.value)
                            }
                          >
                            {availableProjects?.map((project, projectIndex) => (
                              <option key={projectIndex} value={project.name}>
                                {project.name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          data.projects.join(", ")
                        )}
                      </td>
                      <td>
                        {editingRow === index ? (
                          <select
                            value={data.selectedTopic}
                            onChange={(e) =>
                              handleEditTopicChange(index, e.target.value)
                            }
                          >
                            <option value="Meeting">Meeting</option>
                            <option value="Management">Management</option>
                            <option value="Developing">Developing</option>
                            <option value="Testing">Testing</option>
                            <option value="Reviewing">Reviewing</option>
                          </select>
                        ) : (
                          data.selectedTopic
                        )}
                      </td>
                      <td>
                        {editingRow === index ? (
                          <select
                            value={data.slotDuration}
                            onChange={(e) =>
                              handleEditSlotChange(index, e.target.value)
                            }
                          >
                            {[1, 2, 3, 4, 5, 6, 7, 8].map((slot, slotIndex) => (
                              <option key={slotIndex} value={slot}>
                                {slot}
                              </option>
                            ))}
                          </select>
                        ) : (
                          data.slotDuration
                        )}
                      </td>
                      <td>
                        {editingRow === index ? (
                          <button onClick={() => handleSaveEdit(index)}>
                            {" "}
                            <SaveAltIcon />{" "}
                          </button>
                        ) : (
                          <>
                            <button onClick={() => handleDeleteRow(index)}>
                              {" "}
                              <DeleteIcon style={{ fontSize: "18px" }} />{" "}
                            </button>
                            <button onClick={() => handleEditRow(index)}>
                              {" "}
                              <EditIcon style={{ fontSize: "18px" }} />{" "}
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div className={styles.submitButton}>
            <button disabled={disable} onClick={handleSubmit}>
              {" "}
              {loading ? <LoadingSpinner /> : "Submit"}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className={styles.center}>
            <h1 style={{ textAlign: "center" }}>Thank You</h1>
          </div>
          {todayprojects?.latest_projects?.length > 0 && (
            <>
              <p>Yesterday :</p>
              <div className={styles.todayCon}>
                {yesterdayWorkedOnProjects?.map((item, idx) => (
                  <div key={idx} className={styles.latestProjects}>
                    <div className={styles.pnth}>
                      <p>{item.project__name}</p>
                      <p>{item.total_hours} Hours</p>
                    </div>
                    <div className={styles.topics}>
                      {item.topic.map((topic, idx) => (
                        <p key={idx}>{topic}</p>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <p>Today :</p>
              <div className={styles.todayCon}>
                {todayWorkedOnProjects?.map((item, idx) => (
                  <div key={idx} className={styles.latestProjects}>
                    <div className={styles.pnth}>
                      <p>{item.project__name}</p>
                      <p>{item.total_hours} Hours</p>
                    </div>
                    <div className={styles.topics}>
                      {item.topic.map((topic, idx) => (
                        <p key={idx}>{topic}</p>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
export default ActualHours;
