
import React, { useState , useEffect} from 'react';
import styles from "./System.module.css";
import { ProjServices } from "../../../apis/service/ProjServices";
import { ProjectHourService } from "../../../apis/service/ProjectHourServices";
import toast from "react-hot-toast";


function BillableHours() {
   
  const [availableProjects, setAvailableProjects] = useState([]); // State for projects from the backend
  const [billableHoursInput, setBillableHoursInput] = useState("");
  const [selectedProjectForBillable, setSelectedProjectForBillable] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [isTopicDropDownOpen, setIsTopicDropDownOpen] = useState(false);
  const [descriptionInput, setDescriptionInput] = useState("");
  const [daysArray, setDaysArray] = useState([]);


useEffect(() => {
  ProjServices.UserProjects()
    .then((data) => {setAvailableProjects(data);})
    .catch((error) => {console.error("Error fetching projects:", error);});
  getWorkingDays();
}, []);

const getWorkingDays = () =>
  ProjServices.getDays()
    .then((data) => {setDaysArray(data);})
    .catch((error) => {console.error("Error fetching projects:", error);});

const handleBillableSelectProject = (id) => {
  selectedProjectForBillable === id ? setSelectedProjectForBillable(null) : setSelectedProjectForBillable(id);
};

const handleBillableSubmit = () => {
  const data = { project: selectedProjectForBillable, billable_hours: billableHoursInput, description: descriptionInput,
                 topic: selectedTopic, };
  setSelectedTopic(""); setBillableHoursInput(""); setDescriptionInput(""); setSelectedProjectForBillable(null);
  ProjectHourService.addBillableHour(data)
    .then((res) => { toast.success("Billabe Hours Submitted Successfully!");})
    .catch((err) => { console.log(err, "err"); toast.error("Error! couldn't submit the data"); } );
};

const handleTopicSelection = (topic) => {
  setSelectedTopic(topic);
  setIsTopicDropDownOpen(!isTopicDropDownOpen);
};

   return(
    <>
      <div className={styles.ToDo}>
         <h2>Projects</h2>
            <div className={styles.selectedProjects}>
                <div className={styles.selectionContainer}>
                   <div className={styles.availableProjects}>
                      {availableProjects?.length > 0 ? ( availableProjects?.map((project, index) => (
                          <button  key={index}
                            className={`${styles.availableProjectButton} ${selectedProjectForBillable === project.id ? styles.active: ""}`}
                            onClick={() => handleBillableSelectProject(project.id)}>
                            {project.name}
                          </button>
                        ))
                      ) : (<p>No projects available.</p>)}
                    </div>
                  </div>
                </div>
                {/* Task drop down */}
                <div className={styles.todo}>
                  <h2>Tasks</h2>
                  <div className={styles.availableProjects}>
                    <button onClick={() => handleTopicSelection("Meeting")}
                      className={` ${styles.availableProjectButton} ${selectedTopic === "Meeting" ? styles.active : "" }`}>
                      Meeting </button>
                    <button onClick={() => handleTopicSelection("Management")}
                      className={` ${styles.availableProjectButton} ${selectedTopic === "Management" ? styles.active : ""}`}>
                      Management </button>
                    <button onClick={() => handleTopicSelection("Developing")}
                      className={` ${styles.availableProjectButton} ${selectedTopic === "Developing" ? styles.active : ""}`}>
                      Developing </button>
                    <button onClick={() => handleTopicSelection("Testing")}
                      className={` ${styles.availableProjectButton} ${selectedTopic === "Testing" ? styles.active : ""}`}>
                      Testing </button>
                    <button onClick={() => handleTopicSelection("Reviewing")}
                      className={` ${styles.availableProjectButton} ${selectedTopic === "Reviewing" ? styles.active : ""}`}>
                      Reviewing </button>
                    <button onClick={() => handleTopicSelection("SEO work")}
                      className={` ${styles.availableProjectButton} ${selectedTopic === "SEO work" ? styles.active : ""}`}>
                      SEO work </button>
                    <button onClick={() => handleTopicSelection("estimates")}
                      className={` ${styles.availableProjectButton} ${selectedTopic === "estimates" ? styles.active : ""}`}>
                      Estimates </button>
                    <button onClick={() => handleTopicSelection("UI/UX work")}
                      className={` ${styles.availableProjectButton} ${selectedTopic === "UI/UX work" ? styles.active : ""}`}>
                      UI/UX work</button>
                    <button onClick={() => handleTopicSelection("Proposals")}
                      className={` ${styles.availableProjectButton} ${selectedTopic === "Proposals" ? styles.active : "" }`}>
                      Proposals</button>
                    <button onClick={() => handleTopicSelection("")}> clear</button>
                  </div>
                </div>
                <div className={styles.todo}>
                  <h2>Hours?</h2>
                  <input value={billableHoursInput} placeholder="type your hours here"
                    onChange={(e) => setBillableHoursInput(e.target.value)} />
                </div>
                {/* Part Three: Slot duration selection */}
                <div className={styles.slotDurationContainer}>
                  <h2>Description</h2>
                  <textarea className={styles.inputt} placeholder="type your description here"
                    value={descriptionInput} onChange={(e) => setDescriptionInput(e.target.value)}/>
                </div>
              </div>
              <div className={styles.submitButton}> <button onClick={handleBillableSubmit}>Submit</button> </div>
   </>
  );
};
export default BillableHours;