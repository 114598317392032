import { Network } from "../Network";
import { AuthEndpoints } from "../endpoints/AuthEndpoint";

export class AuthServices {
  static UserLogin(data = {}) {
    return Network.fetch(AuthEndpoints.login.url, {
      method: AuthEndpoints.login.method,
      body: JSON.stringify(data),
    });
  }
  static getUserData() {
    return Network.fetch(
      AuthEndpoints.getMe.url,
      {
        method: AuthEndpoints.getMe.method,
      },
      true
    );
  }
  static RefreshToken(data) {
    return Network.fetch(AuthEndpoints.refresh.url, {
      method: AuthEndpoints.refresh.method,
      body: JSON.stringify(data),
    })
  }
  static updateMe(data) {
    return Network.fetch(AuthEndpoints.updateMe.url, {
      method: AuthEndpoints.updateMe.method,
      body: JSON.stringify(data),
    },
      true
    )
  }
  static holidays(data) {
    return Network.fetch(AuthEndpoints.holidays.url, {
      method: AuthEndpoints.holidays.method,
      body: JSON.stringify(data),
    },
      true
    )
  }
  static getAllUsers() {
    return Network.fetch(AuthEndpoints.getAllUsers.url, {
      method: AuthEndpoints.getAllUsers.method,
    },
    )
  }

}
