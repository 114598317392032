import { useEffect, useState } from "react";
import styles from "./style.module.css";
import { ProjectHourService } from "../../../apis/service/ProjectHourServices";

const VacationsReport = () => {
  const [hoursBox, setHoursBox] = useState([]);
  const [fromDate, setFromDate] = useState(getFormattedDate(new Date()));
  const [toDate, setToDate] = useState(getFormattedDate(new Date()));
  const [dates, setDates] = useState([]);
  const [isRange, setIsRange] = useState(false);

  useEffect(() => {setDates(generateDatesUntilEndOfYear());}, []);
  

  const getVacations = async (from, to) => {
    try {
      const res = await ProjectHourService.getVacationsReport({ from, to });
      setHoursBox(res);
    } catch (err) {console.error("Error fetching vacation data:", err);}
  };

  useEffect(() => {getVacations(fromDate, isRange ? toDate : fromDate);}, [fromDate, toDate, isRange]);

  const handleDateChange = (e) => {
    const { id, value } = e.target;
    if (id === "fromDateSelect") {
      setFromDate(value);
      if (!isRange) setToDate(value);
    } 
    else {setToDate(value);
    }
  };

  const handleRangeToggle = () => {
    setIsRange((prev) => !prev);
    if (!isRange) setToDate(fromDate);
  };

  const uniqueUsers = (data) => {
    const seen = new Set();
    return data.filter(({ employee }) => {
      if (seen.has(employee)) return false;
      seen.add(employee);
      return true;
    });
  };

  return (
    <>     
      <div className={styles.dateSelector}>
        <label htmlFor="fromDateSelect">From Date:</label>
        <select id="fromDateSelect" value={fromDate} onChange={handleDateChange}>
          {dates.map((date) => (<option key={date} value={date}>{date}</option>))}
        </select>

        {isRange && (
          <>
            <label htmlFor="toDateSelect">To Date:</label>
            <select id="toDateSelect" value={toDate} onChange={handleDateChange}>
              {dates.map((date) => (<option key={date} value={date}> {date}</option> ))}
            </select>
          </>
        )}

        <button onClick={handleRangeToggle}>{isRange ? "Select Single Date" : "Select Date Range"}</button>
      </div>

      <div>
        <div className={styles.tableCaption}>
          Employee On Vacation Or Sick Leave ({uniqueUsers(hoursBox).length})
        </div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Employee</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {uniqueUsers(hoursBox).map((user) => (
              <tr key={user.employee}>
                <td>{user.employee}</td>
                <td>{user.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

function getFormattedDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function generateDatesUntilEndOfYear() {
    const startDate = new Date(new Date().getFullYear(), 0, 1); 
    const endDate = new Date(new Date().getFullYear(), 11, 32);
    const dates = [];
  
    while (startDate <= endDate) {
      dates.push(getFormattedDate(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }
  
    return dates;
  }
  

export default VacationsReport;
