/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import styles from "./System.module.css";
import { ProjServices } from "../../../apis/service/ProjServices";
import { ProjectHourService } from "../../../apis/service/ProjectHourServices";
import toast from "react-hot-toast";

function AddMoreHours() {
  const [tableData, setTableData] = useState([]);
  const [totalSlotDuration, setTotalSlotDuration] = useState(0);
  const [displayedSelectedProjects, setdisplayedSelectedProjects] = useState(
    []
  );
  const [selectedSlot, setSelectedSlot] = useState(""); //store selected slot duration
  const [selectedTopic, setSelectedTopic] = useState("");
  const [isTopicDropDownOpen, setIsTopicDropDownOpen] = useState(false);
  const [isTakingDayOff, setIsTakingDayOff] = useState(false);
  const [isTakingRestDayOff, setIsTakingRestDayOff] = useState(false);
  const [isDayOffAdded, setIsDayOffAdded] = useState(false); // Initialize to false
  const [availableProjects, setAvailableProjects] = useState([]); // State for projects from the backend
  const [selectedProjects, setSelectedProjects] = useState([]); //store selected projects
  const [disable, setDisable] = useState(true);
  const [isMultiple, setisMultiple] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    getFormattedDate(new Date())
  );
  const [editingRow, setEditingRow] = useState(null); // Track editing state for each row
  const [moreHoursdates, setMoreHoursdates] = useState([]);

  // get available projects frombackend and set them in state variable
  useEffect(() => {
    ProjServices.UserProjects()
      .then((data) => {
        setAvailableProjects(data);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  }, []);

  useEffect(() => {
    setMoreHoursdates(generateDatesFromStartOfYear());
  }, []);

  const handleAdd = () => {
    setDisable(false);

    if (isDayOffAdded) {
      toast.error("You've already added a 'Day Off' entry.");
      return;
    }

    if (isTakingRestDayOff) {
      const restDayOffData = { projects: ["Rest of Day Off"], slotDuration: 0 };
      setTableData([...tableData, restDayOffData]);
      setTotalSlotDuration(totalSlotDuration + restDayOffData.slotDuration);
    } else if (displayedSelectedProjects.length === 0) {
      toast.error("Please Select a Project.");
    } else if (selectedTopic === "") {
      toast.error("Please Select a Topic.");
    } else if (selectedSlot.length === 0) {
      toast.error("Please Select a Slot Duration.");
    } else {
      if (displayedSelectedProjects.length > 1) {
        setisMultiple(true);
      }
      const newData = {
        projects: displayedSelectedProjects,
        slotDuration: selectedSlot.length,
        selectedTopic: selectedTopic,
        multiple: isMultiple,
      };
      console.log(newData.projects, "ssssss");
      // Check if an entry with the same project and topic already exists
      const existingIndex = tableData.findIndex(
        (entry) =>
          entry.projects.join(", ") === newData.projects.join(", ") &&
          entry.selectedTopic === newData.selectedTopic
      );

      if (existingIndex !== -1) {
        // If an entry with the same project and topic exists, update the slot duration
        const updatedTableData = [...tableData];
        updatedTableData[existingIndex].slotDuration += newData.slotDuration;
        setTableData(updatedTableData);
        setTotalSlotDuration(totalSlotDuration + newData.slotDuration);
      } else {
        setTableData([...tableData, newData]);
        setTotalSlotDuration(totalSlotDuration + newData.slotDuration);
      }
    }

    // setSelectedProjects([]);
    setdisplayedSelectedProjects([]);
    setSelectedSlot([]);
    setSelectedTopic("");
  };

  const handleReset = () => {
    //logic to reset
    setSelectedProjects([]);
    setdisplayedSelectedProjects([]);
    setSelectedSlot("");
    setTableData([]);
    setTotalSlotDuration(0);
    setSelectedTopic("");
    setIsTakingDayOff(false);
    setIsTakingRestDayOff(false);
    setIsDayOffAdded(false);
  };

  const handleSaveEdit = () => {
    setEditingRow(null);
  };

  const handleSubmitMoreHours = () => {
    setDisable(true);
    if (tableData.length > 0) {
      // Handle other project hour entries
      const tempArr = [];

      tableData.forEach((data) => {
        const username = localStorage.getItem("username");
        // console.log(data,"dataForeach");
        new Array(data.slotDuration).fill(null).forEach(() => {
          if (data.projects.length > 1) {
            // Handling multiple project entries
            data.projects.forEach((projectName) => {
              const projectIndex = selectedProjects.findIndex(
                (projId) =>
                  availableProjects.find((proj) => proj.id === projId)?.name ===
                  projectName
              );
              const totalBillableMinutes = Math.max(
                data.billableHoursInput * 60 || data.slotDuration * 60,
                0
              ); // Ensure non-negative billable hours

              if (projectIndex !== -1) {
                tempArr.push({
                  topic: data.selectedTopic,
                  actual_minutes: 60 / data.projects.length,
                  date: selectedDate,
                  is_day_off: false,
                  rest_of_day_off: false,
                  project: selectedProjects[projectIndex],
                  employee: username,
                  billable_minutes:
                    totalBillableMinutes /
                    (data.projects.length * data.slotDuration),
                  description: data.descriptionInput,
                });
              }
            });
          } else if (data.projects.length === 1) {
            const projectIndex = selectedProjects.findIndex(
              (projId) =>
                availableProjects.find((proj) => proj.id === projId)?.name ===
                data.projects[0]
            );
            if (projectIndex !== -1) {
              tempArr.push({
                topic: data.selectedTopic,
                actual_minutes: 60,
                date: selectedDate,
                is_day_off: false,
                rest_of_day_off: false,
                project: selectedProjects[projectIndex], // Use the found project ID
                employee: username,
                billable_minutes:
                  (data.billableHoursInput * 60) / data.slotDuration ||
                  (data.slotDuration * 60) / data.slotDuration,
                description: data.descriptionInput,
              });
            }
          }
        });
      });
      ProjectHourService.addProjectHour(tempArr)
        .then((response) => {
          setDisable(false);
          console.log("Project hour data added successfully:", response);
          toast.success("Hours submitted successfully!");
          // Resetting state after submission
          setTableData([]);
          setTotalSlotDuration(0);
          setdisplayedSelectedProjects([]);
          setSelectedProjects([]);
          setSelectedSlot([]);
          setSelectedTopic("");
        })
        .catch((error) => {
          setDisable(true);
          console.error("Error adding project hour data:", error);
          toast.error("Error! couldn't submit the data");
        }).finally = () => {};
    } else {
      console.error("No data to submit.");
      toast.error("Error! their is no data to submit");
    }
  };

  function generateDatesFromStartOfYear() {
    const moreHoursdates = [];
    const startDate = new Date(new Date().getFullYear(), 0, 1);
    const endDate = new Date();

    let currentDate = startDate;
    while (currentDate <= endDate) {
      moreHoursdates.push(getFormattedDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return moreHoursdates;
  }

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleSlotSelection = (slot) => {
    let newSlots = [];
    for (let i = 1; i <= slot; i++) {
      newSlots.push(i);
    }
    setSelectedSlot(newSlots);
  };

  const handleDeleteRow = (index) => {
    const newData = [...tableData];
    const deletedRow = newData.splice(index, 1)[0]; // Remove the selected row
    setTableData(newData);
    setTotalSlotDuration(totalSlotDuration - deletedRow.slotDuration);
  };

  const handleEditRow = (index) => {
    setEditingRow(index);
  };

  const handleTopicSelection = (topic) => {
    setSelectedTopic(topic);
    setIsTopicDropDownOpen(!isTopicDropDownOpen);
  };

  function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleEditProjectChange = (index, value) => {
    const newData = [...tableData];
    newData[index].projects = [value];
    const x = availableProjects.find((item) => value === item.name);
    console.log(x, "xxxxxxxx");
    handleProjectSelectionButton(x.name, x.id);
    setTableData(newData);
    console.log(newData, "newDataProject");
  };

  const handleEditTopicChange = (index, value) => {
    const newData = [...tableData];
    newData[index].selectedTopic = value;
    setTableData(newData);
  };

  const handleEditSlotChange = (index, value) => {
    const newData = [...tableData];
    newData[index].slotDuration = Number(value);
    setTableData(newData);
  };

  const handleProjectSelectionButton = (projectName, projectId) => {
    if (selectedProjects.includes(projectId)) {
      // If the project ID is already in the selectedProjects, remove it
      setSelectedProjects(selectedProjects.filter((id) => id !== projectId));
      setdisplayedSelectedProjects(
        displayedSelectedProjects.filter((name) => name !== projectName)
      );
    } else {
      setSelectedProjects([...selectedProjects, projectId]);
      setdisplayedSelectedProjects([...displayedSelectedProjects, projectName]);
    }
  };

  return (
    <>
      <div className={styles.daySelector}>
        <div className={styles.dateSelector}>
          <label htmlFor="dateSelect"> Here You Can Select A Date: </label>
          <select
            id="dateSelect"
            value={selectedDate}
            onChange={(e) => handleDateChange(e)}
            style={{ width: "40%" }}
          >
            {moreHoursdates?.map((date) => (
              <option key={date} value={date}>
                {" "}
                {date}{" "}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={styles.selected}>
        <p>You Selected : {selectedDate}</p>{" "}
        <p> We Thank You For Your Efforts To Make Bit68 Even Better</p>
      </div>
      <div className={styles.ToDo}>
        <h2>Projects</h2>
        <div className={styles.selectedProjects}>
          <div className={styles.selectionContainer}>
            <div className={styles.availableProjects}>
              {availableProjects?.length > 0 ? (
                availableProjects?.map((project, index) => (
                  <button
                    key={index}
                    className={`${styles.availableProjectButton} ${
                      displayedSelectedProjects.includes(project.name)
                        ? styles.active
                        : ""
                    }`}
                    onClick={() =>
                      handleProjectSelectionButton(project.name, project.id)
                    }
                  >
                    {project.name}
                  </button>
                ))
              ) : (
                <p>No projects available.</p>
              )}
            </div>
          </div>
        </div>
        {/* topics */}
        <div className={styles.todo}>
          <h2>Tasks</h2>
          <div className={styles.availableProjects}>
            <button
              onClick={() => handleTopicSelection("Meeting")}
              className={` ${styles.availableProjectButton} ${
                selectedTopic === "Meeting" ? styles.active : ""
              }`}
            >
              Meeting{" "}
            </button>
            <button
              onClick={() => handleTopicSelection("Management")}
              className={` ${styles.availableProjectButton} ${
                selectedTopic === "Management" ? styles.active : ""
              }`}
            >
              Management{" "}
            </button>
            <button
              onClick={() => handleTopicSelection("Developing")}
              className={` ${styles.availableProjectButton} ${
                selectedTopic === "Developing" ? styles.active : ""
              }`}
            >
              Developing{" "}
            </button>
            <button
              onClick={() => handleTopicSelection("Testing")}
              className={` ${styles.availableProjectButton} ${
                selectedTopic === "Testing" ? styles.active : ""
              }`}
            >
              Testing
            </button>
            <button
              onClick={() => handleTopicSelection("Reviewing")}
              className={` ${styles.availableProjectButton} ${
                selectedTopic === "Reviewing" ? styles.active : ""
              }`}
            >
              Reviewing
            </button>
            <button
              onClick={() => handleTopicSelection("SEO work")}
              className={` ${styles.availableProjectButton} ${
                selectedTopic === "SEO work" ? styles.active : ""
              }`}
            >
              SEO work{" "}
            </button>
            <button
              onClick={() => handleTopicSelection("estimates")}
              className={` ${styles.availableProjectButton} ${
                selectedTopic === "estimates" ? styles.active : ""
              }`}
            >
              Estimates
            </button>
            <button
              onClick={() => handleTopicSelection("UI/UX work")}
              className={` ${styles.availableProjectButton} ${
                selectedTopic === "UI/UX work" ? styles.active : ""
              }`}
            >
              UI/UX work
            </button>
            <button
              onClick={() => handleTopicSelection("Proposals")}
              className={` ${styles.availableProjectButton} ${
                selectedTopic === "Proposals" ? styles.active : ""
              }`}
            >
              Proposals
            </button>
            <button onClick={() => handleTopicSelection("")}>clear</button>
          </div>
        </div>
        {/* Part Three: Slot duration selection */}
        <div className={styles.slotDurationContainer}>
          <h2>Slot Duration</h2>
          <div className={styles.slotDuration}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((slot, index) => (
              <button
                key={index}
                className={selectedSlot.includes(slot) ? styles.active : ""}
                onClick={() => handleSlotSelection(slot)}
              >
                {slot}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.addResetButtons}>
          <button onClick={handleAdd}>Add</button>
          <button onClick={handleReset}>Reset</button>
        </div>
      </div>
      <div className={styles.table}>
        {tableData.length === 0 ? (
          // empty table with one empty cell
          <table>
            <thead>
              <tr>
                <th>Project Name</th>
                <th>Working On</th>
                <th>Slot Duration</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className={styles.scrollableTable}>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        ) : (
          // Render the table with data
          <table className={isTakingDayOff ? styles.dayOffSelected : ""}>
            <thead>
              <tr>
                <th>Project Name</th>
                <th>Working on</th>
                <th>Slot Duration</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className={styles.scrollableTable}>
              {/* Actual data rows */}
              {tableData.map((data, index) => (
                <>
                  <tr key={index}>
                    <td>
                      {editingRow === index ? (
                        <select
                          value={data.projects[0]}
                          onChange={(e) => {
                            handleEditProjectChange(index, e.target.value);
                            console.log(e, "sss");
                          }}
                        >
                          {availableProjects?.map((project, projectIndex) => (
                            <option key={projectIndex} value={project.name}>
                              {project.name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        data.projects.join(", ")
                      )}
                    </td>
                    <td>
                      {editingRow === index ? (
                        <select
                          value={data.selectedTopic}
                          onChange={(e) =>
                            handleEditTopicChange(index, e.target.value)
                          }
                        >
                          <option value="Meeting">Meeting</option>
                          <option value="Management">Management</option>
                          <option value="Developing">Developing</option>
                          <option value="Testing">Testing</option>
                          <option value="Reviewing">Reviewing</option>
                        </select>
                      ) : (
                        data.selectedTopic
                      )}
                    </td>
                    <td>
                      {editingRow === index ? (
                        <select
                          value={data.slotDuration}
                          onChange={(e) =>
                            handleEditSlotChange(index, e.target.value)
                          }
                        >
                          {[1, 2, 3, 4, 5, 6, 7, 8].map((slot, slotIndex) => (
                            <option key={slotIndex} value={slot}>
                              {slot}
                            </option>
                          ))}
                        </select>
                      ) : (
                        data.slotDuration
                      )}
                    </td>
                    <td>
                      {editingRow === index ? (
                        <button onClick={() => handleSaveEdit(index)}>
                          {" "}
                          <SaveAltIcon />{" "}
                        </button>
                      ) : (
                        <>
                          <button onClick={() => handleDeleteRow(index)}>
                            {" "}
                            <DeleteIcon style={{ fontSize: "18px" }} />
                          </button>
                          <button onClick={() => handleEditRow(index)}>
                            <EditIcon style={{ fontSize: "18px" }} />{" "}
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className={styles.submitButton}>
        {" "}
        <button disabled={disable} onClick={handleSubmitMoreHours}>
          Submit
        </button>{" "}
      </div>
    </>
  );
}
export default AddMoreHours;
